<template>
  <div>
    <section class="section" dir="ltr">
      <div class="container" style="top: 50px;">
        <div class="columns">
          <div class="column is-one-third is-offset-4">
            <figure class="image is-128x128" style="margin: auto; bottom: 20px;">
              <img src="../assets/logo.png">
            </figure>
            <b-tabs type="is-boxed" dir="rtl">
              <b-tab-item label="دخول">
                <form @submit.prevent="validateForm('login')" data-vv-scope="login">
                  <div v-if="getError.show" class="notification is-danger">
                    <button class="delete" @click="hideWarning"></button>
                    {{getError.message.message}}
                  </div>
                  <div class="field">
                    <p class="control has-icons-left">
                      <input class="input" type="email" id="logEmail" v-model="formLogin.logEmail" v-validate="'required|email'" name="log_email" :class="{'input': true, 'is-danger': errors.has('login.log_email') }" dir="ltr" autocomplete="off">
                      <span class="help is-danger" v-show="errors.has('login.log_email')">{{ errors.first('login.log_email') }}</span>
                      <span class="icon is-small is-left">
                        <b-icon icon="email" />
                      </span>
                    </p>
                  </div>
                  <div class="field">
                    <p class="control has-icons-left">
                      <input class="input" type="password" v-model="formLogin.logPassword" v-validate="'required'" name="log_password" :class="{'input': true, 'is-danger': errors.has('login.log_password') }" dir="ltr" autocomplete="off">
                      <span class="help is-danger" v-show="errors.has('login.log_password')">{{ errors.first('login.log_password') }}</span>
                      <span class="icon is-small is-left">
                        <b-icon icon="lock" />
                      </span>
                    </p>
                  </div>
                  <div class="field">
                    <a @click="openReset()">اضغط هنا</a> إذا كنت قد نسيت كلمة المرور
                  </div>
                  <div class="field">
                    <p class="control">
                      <button class="button is-primary" type="submit" :class="{ 'is-loading': getLoader }">
                        دخول
                      </button>
                    </p>
                  </div>
                </form>
              </b-tab-item>
              <b-tab-item label="تسجيل">
                <form @submit.prevent="validateForm('signup')" data-vv-scope="signup">
                  <div v-if="getError.show" class="notification is-danger">
                    <button class="delete" @click="hideWarning"></button>
                    {{getError.message.message}}
                  </div>
                  <div class="field">
                    <p class="control has-icons-left">
                      <input class="input" type="email" id="signEmail" v-model="formSignup.signEmail" v-validate="'required|email'" name="sign_email" :class="{'input': true, 'is-danger': errors.has('signup.sign_email') }" dir="ltr" autocomplete="off">
                      <span class="help is-danger" v-show="errors.has('signup.sign_email')">{{ errors.first('signup.sign_email') }}</span>
                      <span class="icon is-small is-left">
                        <b-icon icon="email" />
                      </span>
                    </p>
                  </div>
                  <div class="field">
                    <p class="control has-icons-left">
                      <input class="input" type="password" v-model="formSignup.signPassword" v-validate="'required|min:8'" name="sign_password" :class="{'input': true, 'is-danger': errors.has('signup.sign_password') }" dir="ltr" ref="signup.sign_password" autocomplete="off">
                      <span class="help is-danger" v-show="errors.has('signup.sign_password')">{{ errors.first('signup.sign_password') }}</span>
                      <span class="icon is-small is-left">
                        <b-icon icon="lock" />
                      </span>
                    </p>
                  </div>
                  <div class="field">
                    <p class="control has-icons-left">
                      <input class="input" type="password" v-validate="'required|confirmed:signup.sign_password'" name="re_password" :class="{'input': true, 'is-danger': errors.has('signup.re_password') }" dir="ltr" autocomplete="off">
                      <span class="help is-danger" v-show="errors.has('signup.re_password')">{{ errors.first('signup.re_password') }}</span>
                      <span class="icon is-small is-left">
                        <b-icon icon="lock" />
                      </span>
                    </p>
                  </div>
                  <div class="field">
                    <label class="checkbox">
                      <input name="terms" v-validate="'required'" type="checkbox">
                    </label>
                        الموافقة <a @click="previewTerms()">على شروط الاستخدام</a>.
                    <span class="help is-danger" v-show="errors.has('signup.terms')">{{ errors.first('signup.terms') }}</span>
                  </div>
                  <div class="field">
                    <p class="control">
                      <button class="button is-primary" type="submit" :class="{ 'is-loading': getLoader }">
                        تسجيل
                      </button>
                    </p>
                  </div>
                </form>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>
    <Terms :terms="showTerms"></Terms>
    <div @click="previewTerms" id="postPre"></div>
    <b-modal :active.sync="showReset" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <form @submit.prevent="validateForm('reset')" data-vv-scope="reset">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">تغيير كلمة المرور</p>
          </header>
          <section class="modal-card-body">
            <div v-if="getError.show" class="notification is-danger">
              <button class="delete" @click="hideWarning"></button>
              {{getError.message.message}}
            </div>
            <div class="field">
              <p class="control has-icons-left">
                <input class="input" type="email" id="resEmail" v-model="formReset.resetEmail" v-validate="'required|email'" name="log_email" :class="{'input': true, 'is-danger': errors.has('login.log_email') }" dir="ltr" size="40" autocomplete="off">
                <span class="help is-danger" v-show="errors.has('login.log_email')">{{ errors.first('login.log_email') }}</span>
                <span class="icon is-small is-left">
                  <b-icon icon="email" />
                </span>
              </p>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" type="submit" :class="{ 'is-loading': getLoader }">تغيير</button>
            <button class="button" type="button" @click="openReset">إغلاق</button>
          </footer>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Terms from '@/components/TermsReview'
import ar from 'vee-validate/dist/locale/ar'

export default {
  data () {
    return {
      formLogin: {
        logEmail: null,
        logPassword: null
      },
      formSignup: {
        signEmail: null,
        signPassword: null,
        rePassword: null
      },
      formReset: {
        resetEmail: null
      },
      dictionary: {
        ar: {
          attributes: {
            log_email: '"البريد الإلكتروني"',
            log_password: '"كلمة المرور"',
            sign_email: '"البريد الإلكتروني"',
            sign_password: '"كلمة المرور"',
            re_password: '"إعادة كلمة المرور"',
            terms: '"شروط الاستخدام"'
          },
          messages: {
            confirmed: function (n) { return `${n} لا تتطابق.` }
          }
        }
      },
      postEle: null,
      showTerms: false,
      showReset: false
    }
  },
  components: {
    Terms
  },
  created () {
    this.$validator.localize('ar', ar)
    this.$validator.localize(this.dictionary)
  },
  mounted () {
    this.postEle = document.getElementById('postPre')
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LebDo8UAAAAAElRyYXwTXuhQ_Xjv7wpLTrlZ_-T')
    recaptchaScript.setAttribute('id', 'recapcha')
    document.head.appendChild(recaptchaScript)
  },
  beforeDestroy () {
    let getRecaptcha = document.getElementById('recapcha')
    let footRecaptcha = document.querySelector('.grecaptcha-badge')
    document.head.removeChild(getRecaptcha)
    footRecaptcha.remove(footRecaptcha)
  },
  methods: {
    validateForm: function (scope) {
      var self = this
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LebDo8UAAAAAElRyYXwTXuhQ_Xjv7wpLTrlZ_-T', { action: 'homepage' }).then(function (token) {
              if (token) {
                if (scope === 'login') {
                  self.formLogin.captcha = token
                  self.$store.dispatch('getAccount', self.formLogin)
                } else if (scope === 'signup') {
                  // this.$store.dispatch('setAccount', this.formSignup)
                  self.$store.dispatch('showLoader', true)
                  let params = {
                    appid: self.$store.state.config.XbAppID,
                    email: self.formSignup.signEmail,
                    password: self.formSignup.signPassword,
                    captcha: token
                  }
                  self.XbortRequest('signup', 'post', params, result => {
                    self.$store.dispatch('showLoader', false)
                    if (result.result) {
                      self.successMessage('تم التسجيل بنجاح! يرجى تأكيد التسجيل بتتبع الرابط المرسل إلى بريدكم.')
                    } else {
                      self.errorMessage(result.data)
                    }
                  })
                } else if (scope === 'reset') {
                  // this.$store.dispatch('setAccount', this.formSignup)
                  self.$store.dispatch('showLoader', true)
                  let params = {
                    appid: self.$store.state.config.XbAppID,
                    email: self.formReset.resetEmail,
                    captcha: token
                  }
                  self.XbortRequest('account', 'put', params, result => {
                    self.$store.dispatch('showLoader', false)
                    if (result.result) {
                      self.successMessage('سيتم إرسال رسالة فيها خطوات تغيير كلمة المرور إذا كان بريدكم مسجل لدينا.')
                    } else {
                      self.errorMessage(result.data)
                    }
                  })
                }
              }
            })
          })
        }
      })
    },
    hideWarning: function () {
      this.$store.dispatch('showError', { show: false, message: null })
    },
    previewTerms: function () {
      this.showTerms = !this.showTerms
      if (this.showTerms) {
        this.postEle.style.display = 'block'
      } else {
        this.postEle.style.display = 'none'
      }
    },
    openReset: function () {
      this.showReset = !this.showReset
    }
  },
  computed: {
    ...mapGetters([
      'getLoader',
      'getError'
    ])
  },
  beforeRouteLeave (to, from, next) {
    if (this.showTerms) {
      this.showTerms = !this.showTerms
      this.postEle.style.display = 'none'
      next(false)
    } else {
      next()
    }
  }
}
</script>
