<template>
  <div id="privacy" class="slideout" :class="{active: terms}">
    <div class="panel">
      <div>
        <a type="button" class="delete is-larg" @click="$parent.previewTerms">close</a>
      </div>
      <div class="content">
        <section class="section">
          <p class="title">الشروط والأحكام</p>
          <ul>
            <li>
              باستخدامك براري المدينة، فأنت توافق على هذه الشروط. إذا كنت لا توافق ، فلا تستخدم الموقع أو التطبيق. يحق لنا تغيير هذه الشروط في المستقبل، إذا كنت لا توافق على أي من هذه التغييرات ، فلا تستخدم خدماتنا.
            </li>
            <li>
              أنت توافق على مشاركتك صورك، وأن أي محتوى تساهم به هو ملكك. إذا لم تكن مالك المحتوى ، فيجب الحصول على إذن من المالك قبل المساهمة به، ونحن لن نتعقب ذلك الأمر.
            </li>
            <li>
              فكر قبل نشر أية معلومات قد تحدد هويتك الشخصية للموقع خلال الويب أو تطبيق الهاتف المحمول ، بما في ذلك اسمك بالكامل أو عنوانك، أو معلوماتك الشخصية كرقم الجوال أو عنوان البريد الإلكتروني. إذا كنت دون السن القانوني ، لا تنشر هذه المعلومات على الموقع على الإطلاق.
            </li>
            <li>
              من خلال المساهمة في الموقع  خلال الويب أو تطبيق الهاتف المحمول، فإنك تمنحنا ترخيصًا لاستخدام المحتوى الخاص بك في الدراسات والأبحاث بعد استئذانك شخصيا بالطبع، وأخذ إذنا خطيا منك بذلك. سيبقى المحتوى الخاص بك دومًا ملكك، ويمكنك حذفه في أي وقت. قد تبقى النسخ الاحتياطية في قواعد بياناتنا لبعض الوقت ريثما نعمل على حذفها، ولكن لن تكون متاحة للجمهور.
            </li>
            <li>
              سيكون أي محتوى تساهم به في الموقع من خلال الويب أو تطبيق الهاتف المحمول متاحًا لكل من يستخدم الموقع أو تطبيقات الهاتف المحمول. إذا لم تكن مرتاحًا لهذا، فلا تستخدم هذه الخدمة.
            </li>
            <li>
              نمنع من رفع صورا مسيئة لبلدنا الحبيب أو للآخرين، ومن يفعل ذلك سيتعرض للإبلاغ الفوري عنه للجهات المسؤولة.
            </li>
            <li>
              لا ترفع للموقع أو التطبيق صوراً لحيوانات مصطادة، أو صورا لحيوانات نافقة؛ الموقع يهتم فقط بتوثيق الحيوانات الحية فقط؛ لذا سنلغي أي مشاركة تخالف ذلك.
            </li>
            <li>
              نحن براري المدينة نخلي مسؤوليتنا على الصور التي يتم رفعها بالموقع أو التطبيق من قبل المسجلين معنا, فجميع المواد في الموقع هي ملك لأصحابها، فنحن لا نعطي صلاحيات للاستخدام, بل ولسنا مخولين بذلك. فجميع المواد (الصور والنصوص) التي يتم رفعها غير معروفة المصدر لدينا, فيجب عليك التأكد من الصلاحيات لها قبل الاستخدام.
            </li>
            <li>
              نحن نحتفظ بالحق في رفض الخدمة لأي شخص ، ولإلغاء الحسابات أو الحد من دخول المشارك أو الضيف لأي سبب ، لأي شيء يقوله المشارك أو يكتبه أو يستخدم خدماتنا للقيام به.
            </li>
            <li>
              نحن نأخذ انتهاك الحقوق الملكية لصورنا ( صور مدير الموقع وفريق الموقع الخاص ) على محمل الجد، وسوف نحمي بقوة الحقوق قانونيا.
            </li>
            <li>
              أصحاب الصور والمسجلون في الموقع هم المسؤولين عن متابعة حقوق انتهاك الملكية لصورهم قانونيا؛ فالموقع لن يقوم بذلك عنهم.
            </li>
            <li>
              إذا كنت مالك الحقوق لأي صورة على موقع براري المدينة وكنت لا ترغب في السماح لنا بمواصلة ظهور صورك وتوثيقاتك في موقعنا، كل ما عليك هو إلغاء اشتراكك وسوف تمحى مشاركاتك تبعا لذلك.
            </li>
          </ul>
        </section>
        <section class="section">
          <p class="title">
            الخصوصية والمعلومات الشخصية
          </p>
          <ul>
            <li>
              نحن ملتزمون بالحفاظ على أمن معلوماتك الشخصية. لن ننشر عنوان بريدك الإلكتروني أو معلوماتك الشخصية أو كلماتك أو نعيد استخدامها بدون إذن منك باستثناء الأغراض الترويجية أو الحملات الأخرى داخل الموقع نفسه، كإعلامك عن أنشطتنا ورحلاتنا، وتزويدك ببعض الأخبار ذات العلاقة.
            </li>
            <li>
              سنبذل قصارى جهدنا لمواصلة تقديم تطبيقات الجوال والموقع ، للتأكد من التزام جميع المستخدمين بهذه الشروط ، والتأكد من أن الموقع آمن للاستخدام. ومع ذلك ، لا يمكننا ضمان ذلك ولن نكون مسئولين عن أي أضرار ناجمة عن استخدام أو عدم القدرة على استخدام موقع الويب أو تطبيقات الهاتف المحمول أو أي من محتوياتها أو من أي إجراء تم اتخاذه (أو لم يتم اتخاذه) باعتباره نتيجة استخدام موقع الويب أو أي من هذه المحتويات.
            </li>
          </ul>
        </section>
        <section class="section">
          <p class="subtitle has-text-centered">
            إذا كان لديك أي أسئلة أو استفسارات فلا تتردد في الاتصال بنا في أي وقت على: <br>info@madinahwild.com
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      back: null
    }
  },
  props: [
    'terms'
  ],
  watch: {
    '$parent.showTerms': function () {
      if (this.$parent.showTerms) {
        document.addEventListener('backbutton', clicked => {
          this.back = clicked
        })
      }
    }
  },
  beforeDestroy () {
    console.log(this.back)
  }
}
</script>
